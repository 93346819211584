import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import MsExcelLogoFeatured from 'images/featured-image/ms-excel-logo-shipkoo.png';
import BlankInventoryImg from 'images/blog-images/blank-inventory.png';
import OnlineShoppingImgSrc from 'images/blog-images/online-shopping-cart.png';
import ShipkooInventoryManagementImgSrc from 'images/blog-images/shipkoo-inventory-management.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query InventoryManagementExcelImg {
      file(relativePath: { eq: "shipkoo-inventory-management.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo inventory management"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Inventory Management Using Excel | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-inventory-system" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Inventory Management Using Excel",
              content: intl.formatMessage({ id: "blog-post-meta-title-inventory-system" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: MsExcelLogoFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="inventory-management-using-excel"
        title="Inventory Management Using Excel"
        date="2020-06-15"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              Having organized inventory means you can provide a stable and dependable service to your customers.
            </p>

            <p>
              If you are still in a starting phase of your business, you may use Microsoft Excel or Google Spreadsheet to track your inventory; however, it may only work if you have only a few products. 
            </p>

            <Image src={BlankInventoryImg} className="" alt="blank inventory"/>

            <p>
              <strong>
                Excel Inventory Management: How It Works
              </strong>
            </p>

            <p>
              There are formatted templates that you can use in Microsoft Excel, but you can also design your own sheet. 
            </p>

            <p>
              <strong>
                Method 1: Use of Template
              </strong>
            </p>

            <p className="no-bottom">
              <strong>
                Step 1. Open Microsoft Excel 
              </strong>
            </p>

            <p>
              You will be able to find Microsoft Excel on your desktop or look it up using the Windows search function.
            </p>

            <p className="no-bottom">
              <strong>
                Step 2. Click the search bar
              </strong>
            </p>

            <p>
              A search bar is at the top of the Excel window. Type the word "inventory list" into the search bar then hit ↵ Enter. 
            </p>

            <p className="no-bottom">
              <strong>
                Step 3. Choose a template
              </strong>
            </p>

            <p>
              Click on the inventory list template that is based on what you need. 
            </p>

            <p className="no-bottom">
              <strong>
                Step 4: Create
              </strong>
            </p>

            <p>
              Click create to continue 
            </p>

            <p className="no-bottom">
              <strong>
                Step 5: Enter the inventory information 
              </strong>
            </p>

            <p>
              Wait for the template to load and enter all the details needed. Double click a pre-filled cell to modify the details. 
            </p>

            <p>
              <strong>
                Method 2: Design your sheet  
              </strong>
            </p>

            <p className="no-bottom">
              <strong>
                Step 1. Open Microsoft Excel
              </strong>
            </p>

            <p>
              You will be able to find Microsoft Excel on your desktop or look it up using the Windows search function. Select a blank workbook. 
            </p>

            <p className="no-bottom">
              <strong>
                Step 2: Create an inventory list header
              </strong>
            </p>

            <p>
              In the following cells, enter the following: 
            </p>

            <p>
              A1 - Item Name <br />
              B1 - Item SKU <br />
              C1 - Item Cost <br />
              D1 - Quantity
            </p>

            <p className="no-bottom">
              <strong>
                Step 3: Add your data
              </strong>
            </p>

            <p>
              Enter the values for the Item Name, Item, SKU, Item Cost, and Quantity. You may continue to add columns depending on the data that is needed.
            </p>

            <p className="no-bottom">
              <strong>
                Step 4. Save 
              </strong>
            </p>

            <p>
              Save your spreadsheet with your desired file name. You can copy it again if you need the same template.
            </p>

            <p>
              There are also important excel functions that you need in order to speed up the process:
            </p>

            <p>
              <strong>SUM.</strong> Use this function to add up any values. <br />
              <strong>RANK.</strong> Use this function to arrange items based on sales quantity, inventory quantity, or the number of products that you have received to your inventory. <br />
              <strong>SORT.</strong> Use this function to sort your inventory items by different parameters to quickly view your products based on sales, profitability or remaining stocks.
            </p>

            <p>
              Excel inventory tracking works; however, as your business grows, you may notice certain limitations of Excel as compared with a fully functional inventory management system.
            </p>

            <p>
              <strong>
                Disadvantages of Excel Inventory Management
              </strong>
            </p>

            <p className="no-bottom">
              <strong>
                Prone to errors
              </strong>
            </p>

            <p>
              All information is entered manually using Excel, so there’s a chance you might forget to record a transaction or simply enter a wrong value. 
            </p>

            <p className="no-bottom">
              <strong>
                Too many formulas 
              </strong>
            </p>

            <p>
              The tricky part for many users is that the calculations must be entered into the spreadsheet using formulas. And this is something you must learn first.
            </p>

            <p className="no-bottom">
              <strong>
                Lack of real-time results  
              </strong>
            </p>

            <p>
              A spreadsheet is not capable of tracking real-time stock flows. It requires constant monitoring to ensure that each transaction is accounted for. You may need dedicated personnel to check your stocks. 
            </p>

            <Image src={ShipkooInventoryManagementImgSrc} alt="shipkoo inventory management"/>

            <p className="no-bottom">
              <strong>
                Conclusion:  
              </strong>
            </p>

            <p>
              Many companies offer inventory management software that can help you end all the manual works. But you can always make your life easier and focus on growing your business. You can eliminate the hassle of inventory by using <a href="/services/ecommerce-fulfillment">fulfillment and shipping solutions</a> like Shipkoo. Shipkoo helps you save money on fulfillment, providing you real-time reports and ship items to your customers with no problems.  
            </p>

            <p>
              For fulfillment, inventory management, and shipping, we got you covered. 
            </p>
            
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;